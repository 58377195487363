import * as React from "react"
import {Helmet} from "react-helmet";

const NotFoundPage = () => {
  return (
    <main>
      <Helmet>
          <meta http-equiv = "refresh" content = "0; url = /" />
      </Helmet>
      <title>Страница не найдена</title>
      <h1>Страница не найдена</h1>
      <p>Вы будете перенаправлены на главную страницу сайта автоматически </p>
    </main>
  )
}

export default NotFoundPage
